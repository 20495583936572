import { useCallback, useEffect, useState, useRef } from 'react';
import './App.css';
import Image from './Image';
import Timer from './Timer';
import useSound from 'use-sound';
import gamesound from './assets/loop.mp3';
import videoWin from './assets/Hostopia_Video.mp4';
import baseImages from './images';
import ReactPlayer from 'react-player';

const makeImageArray = () => {
  return [...baseImages, ...baseImages].sort(() => 0.5 - Math.random());
};

const INITIAL_TIME = 50;

function App() {
  const [imageArray, setImageArray] = useState(makeImageArray());
  const [play, { duration, stop }] = useSound(gamesound, {
    interrupt: true,
    loop: true,
  });
  const [couple, setCouple] = useState([]);
  const [indexCouple, setIndexCouple] = useState([]);
  const [flippedArray, setFlippedArray] = useState([]);
  const [isPlaying, setPlaying] = useState(true);
  const [isAudioPlaying, setAudioPlaying] = useState(false);
  const [isStarted, setStarted] = useState(false);
  const [canRestart, setRestart] = useState(false);
  const timerRef = useRef();

  useEffect(() => {
    play();

    return () => {
      stop();
    };
  }, [play, stop, duration]);

  useEffect(() => {
    if (couple.length === 2) {
      if (couple[0] === couple[1]) {
        setFlippedArray((state) => [...state, ...indexCouple]);
        setTimeout(() => {
          setCouple([]);
          setIndexCouple([]);
        }, 300);
      } else {
        setTimeout(() => {
          setCouple([]);
          setIndexCouple([]);
        }, 300);
      }
    }
  }, [couple, indexCouple]);

  useEffect(() => {
    if (flippedArray.length === baseImages.length * 2) {
      stop();
      setPlaying(true);
      const el = document.getElementById('video')
      if (el) {
        el.play()
      }
      timerRef.current.timerPause();
    }
  }, [flippedArray.length]);

  const clickHandler = useCallback(
    (image, index) => {
      if (couple.length === 2) return;
      if (flippedArray.includes(index)) return;
      if (indexCouple.includes(index)) return;

      if (!couple[0]) {
        setIndexCouple([index]);
        setCouple([image.name]);
        return;
      }
      if (!couple[1]) {
        setIndexCouple([...indexCouple, index]);
        setCouple([...couple, image.name]);
        return;
      }
    },
    [couple, flippedArray, indexCouple]
  );

  const startHandler = useCallback(
    (time = INITIAL_TIME * 1000) => {
      setStarted(true);
      timerRef.current.timerStart(time);
      if (canRestart) {
        setImageArray(makeImageArray());
        setCouple([]);
        setIndexCouple([]);
        setFlippedArray([]);
        setRestart(false);
      }
    },
    [canRestart, isAudioPlaying]
  );

  const endCallback = useCallback(() => {
    setStarted(false);
    if (flippedArray.length !== baseImages.length * 2) {
      setRestart(true);
    }
  }, [flippedArray.length]);

  return (
    <div className='container'>
      <audio src={gamesound} loop id='audio' />
      <header className='header'>
        <div className='text-wrapper'>
          <h2>
            {canRestart
              ? 'Sorry, your time ran out.'
              : flippedArray.length === baseImages.length * 2
              ? 'Congratulations! You did it!'
              : 'Solve our Match Game'}
          </h2>
          <p>
            {canRestart ? (
              <>
                Please try again
              </>
            ) : flippedArray.length === baseImages.length * 2 ? (
              <>
              </>
            ) : (
              <>
                in {INITIAL_TIME} seconds or less to reveal a present.
              </>
            )}
          </p>
        </div>
      </header>
      <div className='image-container'>
        <div className='holder' />
        <div className={`video ${isPlaying ? '' : 'video-hidden'}`}>
          <video src={videoWin} className='video-item' id="video" />
          {/* <ReactPlayer
            playing={isPlaying}
            width='100%'
            height='100%'
            playsinline
            url={videoWin}
          /> */}
        </div>
        <div className='images'>
          {imageArray.map((image, index) => (
            <Image
              key={index}
              index={index}
              image={image}
              onClick={clickHandler}
              isFlipped={
                indexCouple.includes(index) || flippedArray.includes(index)
              }
              isOpened={flippedArray.includes(index)}
              isStarted={isStarted}
            />
          ))}
        </div>
      </div>
      <footer className={`footer ${isStarted ? 'started' : ''}`}>
        <Timer
          ref={timerRef}
          isStarted={isStarted}
          endCallback={endCallback}
          initialTime={INITIAL_TIME * 1000}
        />
        {!isStarted && (
          <button className='control-button' onClick={startHandler}>
            {canRestart ? 'Restart' : 'Start'}
          </button>
        )}
      </footer>
    </div>
  );
}

export default App;
