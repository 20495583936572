import React, { useEffect, useImperativeHandle, forwardRef } from 'react';
import useCountDown from 'react-countdown-hook';

const Timer = forwardRef(
  (
    { initialTime = 20 * 1000, interval = 90, isStarted = false, endCallback },
    ref
  ) => {
    const [timeLeft, { start, pause, reset }] = useCountDown(
      initialTime,
      interval
    );

    useEffect(() => {
      if (isStarted && timeLeft === 0) endCallback();
    }, [endCallback, isStarted, timeLeft]);

    useImperativeHandle(ref, () => ({
      timerStart: () => {
        start();
      },
      timerPause: () => {
        pause();
      },
      timerReset: () => {
        reset();
      },
    }));

    if (!isStarted) return null;
    return <>{(timeLeft / 1000).toFixed(2).split('.').join(':')}</>;
  }
);

export default Timer;
